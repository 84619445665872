<template>
  <div>
    <!-- 商品展示部分 -->
    <div class="goods">
      <div class="content content_box">
        <div class="icon_nav_box">
          <img
            class="icon_nav_img"
            src="../assets/img/icon_nav1 (6).png"
            alt=""
          />
          <div>正品保证</div>
        </div>
        <div class="icon_nav_box">
          <img
            class="icon_nav_img"
            src="../assets/img/icon_nav1 (5).png"
            alt=""
          />
          <div>品类齐全</div>
        </div>
        <div class="icon_nav_box">
          <img
            class="icon_nav_img"
            src="../assets/img/icon_nav1 (4).png"
            alt=""
          />
          <div>专业服务</div>
        </div>
        <div class="icon_nav_box">
          <img
            class="icon_nav_img"
            src="../assets/img/icon_nav1 (3).png"
            alt=""
          />
          <div>价格优惠</div>
        </div>
        <div class="icon_nav_box">
          <img
            class="icon_nav_img"
            src="../assets/img/icon_nav1 (2).png"
            alt=""
          />
          <div>售后无忧</div>
        </div>
        <div class="icon_nav_box">
          <img
            class="icon_nav_img"
            src="../assets/img/icon_nav1 (1).png"
            alt=""
          />
          <div>极速发货</div>
        </div>
      </div>
      <div class="content logo_tt">品牌分类</div>
      <div class="content logos">
        <el-carousel
          height="130px"
          :interval="2000"
          style="z-index: 1"
          arrow="never"
        >
          <el-carousel-item>
            <div>
              <img
                class="logos_img"
                src="../assets/img/1.png"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584638801911905492.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584638934279076894.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584639039189538292.jpg"
                alt=""
              />

              <img
                class="logos_img"
                src="../assets/img/1584639039189538292.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584644929346852817.png"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584645166902588573.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584645264207464214.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584645456192057861.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584655709085761060.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584656556660451667.jpg"
                alt=""
              />

              <img
                class="logos_img"
                src="../assets/img/1584656618550718353.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584656837034332028.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584656997721477164.png"
                alt=""
              />
              
              
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
             <img
                class="logos_img"
                src="../assets/img/1584657154927004113.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584657230707555964.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584657633099554530.png"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584657981536281769.jpg"
                alt=""
              />


               <img
                class="logos_img"
                src="../assets/img/1584720127046231165.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584754746421912942.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1584754819306458968.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1585846635641854492.jpg"
                alt=""
              />
               <img
                class="logos_img"
                src="../assets/img/1585847207332432207.png"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1585848682314870992.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1585848989818018891.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1585849233922502132.png"
                alt=""
              />
               <img
                class="logos_img"
                src="../assets/img/1585849726894293108.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1585851430346119507.jpg"
                alt=""
              />
              <img
                class="logos_img"
                src="../assets/img/1585852180351777571.jpg"
                alt=""
              />
             
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content logo_tt">新品上架</div>
      <div class="content goods_hot">
        <div class="goods_hot_box" @click="goDetail(item.goods_xh)" v-for="item in itemsList" :key="item.id">
          <img class="hot_img" :src="URL + item.cover_img" alt="">
          <div class="hot_tt">{{item.goods_name}}</div>
          <div class="hot_bm">
            <div class="hot_bm_lt">
              ￥<span>{{item.shop_price}}</span>
              </div>
            <div class="hot_bm_rt">立即购买</div>
          </div>
        </div>
        
      </div>
      <!-- color: ['','#','#', '#','#','#','#'], -->
      <div class="content goods_box" v-for="(item,i) in indexList" :key="i">
        <div v-show="i === 0" class="goods_top" style="background-color: #F8A536">
          <div class="goods_top_lt">{{item.name}}</div>
          <div class="goods_top_rt">
            <div
              @click="goList(it.id, 2)"
              v-for="(it,e) in item.children"
              :key="e"
            >{{it.name}}</div>
            <div @click="goList(item.id, 1)" class="gengduo">更多</div>
          </div>
        </div>
        <div v-show="i === 1" class="goods_top" style="background-color: #32C1F7">
          <div class="goods_top_lt">{{item.name}}</div>
          <div class="goods_top_rt">
            <div
              @click="goList(it.id, 2)"
              v-for="(it,e) in item.children"
              :key="e"
            >{{it.name}}</div>
            <div @click="goList(item.id, 1)" class="gengduo">更多</div>
          </div>
        </div>
        <div v-show="i === 2" class="goods_top" style="background-color: #FA4541">
          <div class="goods_top_lt">{{item.name}}</div>
          <div class="goods_top_rt">
            <div
              @click="goList(it.id, 2)"
              v-for="(it,e) in item.children"
              :key="e"
            >{{it.name}}</div>
            <div @click="goList(item.id, 1)" class="gengduo">更多</div>
          </div>
        </div>
        <div v-show="i === 3" class="goods_top" style="background-color: #00D6C0">
          <div class="goods_top_lt">{{item.name}}</div>
          <div class="goods_top_rt">
            <div
              @click="goList(it.id, 2)"
              v-for="(it,e) in item.children"
              :key="e"
            >{{it.name}}</div>
            <div @click="goList(item.id, 1)" class="gengduo">更多</div>
          </div>
        </div>
        <div v-show="i === 4" class="goods_top" style="background-color: #FA4087">
          <div class="goods_top_lt">{{item.name}}</div>
          <div class="goods_top_rt">
            <div
              @click="goList(it.id, 2)"
              v-for="(it,e) in item.children"
              :key="e"
            >{{it.name}}</div>
            <div @click="goList(item.id, 1)" class="gengduo">更多</div>
          </div>
        </div>
        <div v-show="i === 5" class="goods_top" style="background-color: #707EFA">
          <div class="goods_top_lt">{{item.name}}</div>
          <div class="goods_top_rt">
            <div
              @click="goList(it.id, 2)"
              v-for="(it,e) in item.children"
              :key="e"
            >{{it.name}}</div>
            <div @click="goList(item.id, 1)" class="gengduo">更多</div>
          </div>
        </div>
        <div v-show="i === 6" class="goods_top" style="background-color: #F647FA">
          <div class="goods_top_lt">{{item.name}}</div>
          <div class="goods_top_rt">
            <div
              @click="goList(it.id, 2)"
              v-for="(it,e) in item.children"
              :key="e"
            >{{it.name}}</div>
            <div @click="goList(item.id, 1)" class="gengduo">更多</div>
          </div>
        </div>
        
        <div class="goods_bm">
          <div class="img_lt">
            <!-- <img v-if="i == 0" src="../assets/img/a111@2x.png" alt="">
            <img v-if="i == 1" src="../assets/img/a222@2x.png" alt="">
            <img v-if="i == 2" src="../assets/img/a333@2x.png" alt="">
            <img v-if="i == 3" src="../assets/img/a555@2x.png" alt="">
            <img v-if="i == 4" src="../assets/img/a666@2x.png" alt="">
            <img v-if="i == 5" src="../assets/img/a777@2x.png" alt="">
            <img v-if="i == 6" src="../assets/img/a888@2x.png" alt=""> -->
            <img v-if="i == 0" src="../assets/img/a111@2x.png" alt="">
            <img v-if="i == 1" src="../assets/img/a222@2x.png" alt="">
            <img v-if="i == 2" src="../assets/img/a777@2x.png" alt="">
            <img v-if="i == 3" src="../assets/img/a666@2x.png" alt="">
            <img v-if="i == 4" src="../assets/img/a333@2x.png" alt="">
            <img v-if="i == 5" src="../assets/img/a555@2x.png" alt="">
            <img v-if="i == 6" src="../assets/img/a888@2x.png" alt="">

          </div>
          <div class="goods_rt_box">
              <div @click="goDetail(it1.goods_xh)" class="goods_bm_rt" v-for="(it1, e1) in item.goods" :key="e1">
                <div class="img_lt_box">
                  <img class="img_lt1" :src="URL + it1.cover_img" alt="">
                </div>
                <div class="goods_tt">{{it1.goods_name}}</div>
                <div class="money">
                  <div>
                    ￥
                  <span>{{it1.shop_price}}</span>
                  </div>
                  
                  <div class="hot_bm_rt">立即购买</div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data() {
    return {
      
      bannerList: [],
      indexList: [],
      URL: '',
      // 分类列表
      itemsList: []
    }
  },
  mounted() {
    this.URL = this.$URL
    this.banner()
    this.index()
    this.items()
  },
  methods: {
    //   轮播
    async banner() {
      const { data: res } = await this.$http.get('index.php/index/index/banner')
      if (res.code !== 1) return
      this.bannerList = res.data
    },
    //   首页商品
    async index() {
      const { data: res } = await this.$http.get('index.php/index/goods/index')
      if (res.code !== 1) return
      this.indexList = res.data
      // this.indexList.forEach(it => {
      //   it.n = 0
      // })
    },
    // 商品分类列表
    async items() {
      const { data: res } = await this.$http.post('index.php/index/goods/items', {
        page: {
          offset: 1,
          limit: 4
        }
      })
      if (res.code !== 1) return
      this.itemsList = res.data.data
     
    },
    // 鼠标触摸触发，展示不同商品
    // mou(index,i) {
    //   console.log(i)
    //   this.indexList[index].n = i
    //   this.$forceUpdate()
    // },
    // 点击跳转到商品详情页
    goDetail(id) {
        // this.$router.push('/detail?id=' + id)
        // window.open(this.URL + '/detail?id=' + id)
        let routeUrl = this.$router.resolve({
        path: '/detail?id=' + id
      });
      window.open(routeUrl.href, '_blank');
    },
    // 点击跳转到商品列表页面
    goList(id,type) {
      // this.$router.push('/list?id=' + id + '&type=' + type)
      // window.open(this.URL + '/list?id=' + id + '&type=' + type)
      // this.reload()
      let routeUrl = this.$router.resolve({
        path: '/list?id=' + id + '&type=' + type
      });
      window.open(routeUrl.href, '_blank');
    },
  }
}
</script>
<style lang="less" scoped>
// 轮播
.lunbo_box {
  .img_lunbo {
    width: 100%;
    height: 500px;
  }
}
// 商品展示部分
.goods {
  width: 100%;
  background-color: #f0f0f0;
  padding: 20px 0 10px 0;
  padding-top: 40px;
  width: 100%;
  background-color: #f0f0f0;

  .content_box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    height: 90px;
    font-size: 16px;
    color: #134daf;
    font-weight: 700;
    .icon_nav_box {
      text-align: center;
      .icon_nav_img {
        width: 46px;
        height: 46px;
      }
    }
  }
  .logo_tt {
    font-size: 24px;
    font-weight: 700;
    padding: 35px 0 20px 0;
  }
  .logos {
    width: 1176px;
    height: 130px;
    background-color: #fff;
    padding: 12px;
    div {
      width: 100%;
      height: 100%;

      .logos_img {
        width: 140px;
        height: 60px;
        margin-right: 32px;
      }
      img:nth-child(7n) {
        margin-right: 0;
      }
    }
  }
  .goods_hot {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    .goods_hot_box {
      width: 280px;
      height: 410px;
      background-color: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      .hot_img {
        display: block;
        height: 280px;
        width: 280px;
        
      }
      .hot_img:hover {
            // transform: scale(1.01);
           
          }
      .hot_tt {
        height: 44px;
        font-size: 14px;
        color: #333;
        // height: 38px;
        // width: 266px;
        line-height: 22px;
        margin: 24px 10px 12px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      .hot_bm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        .hot_bm_lt {
          font-size: 12px;
          color: #e85050;
          font-weight: 700;
          span {
            font-size: 18px;
          }
        }
        .hot_bm_rt {
          width: 80px;
          height: 34px;
          background-color: #e85050;
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 34px;
        }
      }
    }
    .goods_hot_box:hover {
        cursor: pointer;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);
        transform: scale(1.02, 1.02);
         border: 1px solid #E85050;
      }
  }
  .goods_box {
    
    margin-bottom: 10px;
    .goods_top {
      width: 100%;
      height: 60px;
      
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      .goods_top_lt {
        font-size: 18px;
        font-weight: 700;
        margin-left: 20px;
      }
      .goods_top_rt {
        display: inline-flex;
        align-items: center;
        div {
          margin-right: 40px;
          font-size: 14px;
          cursor: pointer;
        }
        div:hover {
          // color: #e85050;
        }
       
      }
    }
    
    .goods_bm {
      display: flex;
      justify-content: space-between;
      padding: 10px 0 30px;
      .img_lt {
        width: 240px;
        min-width: 240px;
        height: 660px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .goods_rt_box {
        flex: auto;
        display: flex;
        flex-wrap: wrap;
      }
      .goods_bm_rt {
        width: 228px;
        height: 322px;
        background-color: #fff;
        border: 1px solid #fff;
        // padding: 0 10px 10px 10px;
        margin-left: 9px;
        margin-bottom: 10px;
        .img_lt_box {
          min-width: 228px;
          max-width: 228px;
          height: 250px;
          .img_lt1 {
            width: 100%;
            height: 100%;
          }
          .img_lt1:hover {
            // transform: scale(1.01);
           
          }
        }
        .goods_tt {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 5px;
          padding: 0 10px;
        }
        .money {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          color: #e85050;
          font-weight: 700;
          padding: 6px 10px 0;
          span {
            font-size: 18px;
          }
          .hot_bm_rt {
          width: 80px;
          height: 34px;
          background-color: #e85050;
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 34px;
        }
        }
      }
      .goods_bm_rt:hover {
        cursor: pointer;
        box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);
        transform: scale(1.02, 1.02);
         border: 1px solid #E85050;
      }
    }
  }
}
</style>

