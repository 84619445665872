<template>
  <div>
    <div class="detail_box">
      <div class="content detail_box1">
        <div class="tt">
          <span>当前位置：</span>
          <!-- <span>商品列表</span> -->
          <!-- <img src="../assets/img/icon_inter@2x.png" alt=""> -->
          <span>商品详情</span>
        </div>
        <div class="detail_box_cneter">
          <div>
            <div class="lf">
              <div class="jingxuan_tt">商品精选</div>
              <div
                class="goods_box"
                v-for="(item,i) in recommendList"
                :key="i"
                @click="godetail(item.goods_xh)"
              >
                <img :src="URL + item.cover_img" alt="">
                <div class="tt_rong">{{item.goods_name}}</div>
                <div class="money">
                  ￥
                  <span>{{item.shop_price}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="rt">
            <div class="rt_top">
              <div class="rt_top_lt">
                <!-- <img class="goods_img" :src="URL + detailList.cover_img" alt=""> -->
                <div class="goods_imgbox">
                  <pic-zoom class="goods_img" :url="URL + pic[isflow]" :scale="2"></pic-zoom>
                </div>
                <div class="img_box">
                  <!-- <img class="lt_img" src="../assets/img/icon_inter@2x.png" alt=""> -->
                  <el-carousel @change="carouselchange" style="width:282px;" :interval="5000" type="card" height="74px">
                    <el-carousel-item v-for="(it,e) in pic" :key="e">
                      <img class="img_lun" :src="URL + it" alt="">
                    </el-carousel-item>
                    <!-- <el-carousel-item >
                      <img class="img_lun" :src="URL + detailList.cover_img" alt="">
                    </el-carousel-item>
                    <el-carousel-item >
                      <img class="img_lun" :src="URL + detailList.cover_img" alt="">
                    </el-carousel-item> -->
                  </el-carousel>
                  <!-- <img class="rt_img" src="../assets/img/icon_inter@2x.png" alt=""> -->
                </div>
              </div>
              <div class="rt_top_rt">
                <div class="rt_top_rt_name">{{detailList.goods_name}}</div>
                <div class="rt_top_rt_tt">{{detailList.goods_sn}}</div>
                <div>
                  类别：
                  <span>{{detailList.category_name}}</span>
                </div>
                <div>
                  品牌：
                  <span>{{detailList.brand_name}}</span>
                </div>
                <div>
                  报价：
                  <span class="jiage">￥{{detailList.shop_price}}</span>
                </div>
                <div>
                  库存：
                  <span>{{detailList.goods_num}}</span>
                </div>
                <div>
                  服务：
                  <span>
                    由
                    <span class="shangcheng_tt">{{site_name}}</span>发货并提供售后服务
                  </span>
                </div>
                <div class="bm_btn">
                  <div @click="goOrder">立即购买</div>
                  <div @click="goCart">加入购物车</div>
                </div>
              </div>
            </div>
            <div class="rt_bm">
              <div class="rt_bm_tab">
                <div @click="xiangqing" :class="i == 0 ? 'rt_active' : ''">商品详情</div>
                <div @click="canshu" :class="i == 1 ? 'rt_active' : ''">商品参数</div>
              </div>
              <div class="img_content">
                <div v-if="i == 0" v-html="content"></div>
                <table
                  v-if="i == 1"
                  width="927"
                  border="0"
                  cellspacing="0"
                  cellpadding="0"
                  text-agmin="right"
                >
                  <tr v-for="(item, i) in detailList.params" :key="i">
                    <td width="100">{{item.cssm}}</td>
                    <td width="500">{{item.csz}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PicZoom from 'vue-piczoom'
export default {
  inject: ['reload'],
  data() {
    return {
      id: '',
      detailList: {},
      URL: '',
      i: 0,
      recommendList: [],
      cart: [],
      site_name: '',
      content: '',
      // 控制轮播切换
      isflow: 0,
      pic: []
    }
  },
  components: {
    PicZoom
  },
  mounted() {
    
    this.URL = this.$URL
    this.id = this.$route.query.id
    this.detail()
    this.recommend()
  },
  methods: {
    // 精选商品
    async recommend() {
      const { data: res } = await this.$http('index.php/index/goods/recommend')
      if (res.code !== 1) return
      this.recommendList = res.data
    },
    // 商品详情
    async detail() {
      this.content = ''
      this.detailList = {}
      const { data: res } = await this.$http.get(
        'index.php/index/goods/detail',
        {
          params: {
            goods_xh: this.id
          }
        }
      )
      if (res.code !== 1) return
      this.detailList = res.data
      this.pic = res.data.pic
      this.content = res.data.content.replace(new RegExp('<img', 'gi'), '<img style="width: 100%;"');
      this.site_name = window.sessionStorage.getItem('site_name')
      window.document.title =
        res.data.goods_name + '-' + window.sessionStorage.getItem('site_name')
    },
    // 切换详情
    xiangqing() {
      this.i = 0
    },
    // 切换参数
    canshu() {
      this.i = 1
    },
    // 点击商品精选
    godetail(id) {
      this.id = id
      this.detail()
    },
    // 加入购物车
    goCart() {
      this.cart = JSON.parse(window.localStorage.getItem('cart_2v')) || []
      if (this.cart.length > 0) {
        const isflow = this.cart.some(it => +it.id === this.detailList.id)
        console.log('is', isflow)
        if (!isflow) {
          this.detailList.num = 1
          this.cart.push(this.detailList)
          window.localStorage.setItem('cart_2v', JSON.stringify(this.cart))
          // this._updateCart2(this.cart)
          return this.$message.success('加入购物车成功')
        } else {
          this.$message.error('该商品已经加入购物车')
        }
      } else {
        this.detailList.num = 1
        this.cart.push(this.detailList)
        window.localStorage.setItem('cart_2v', JSON.stringify(this.cart))
        //  this._updateCart2(this.cart)
        return this.$message.success('加入购物车成功')
      }
    },
    // 总件数
    //  _updateCart2(cart) {

    //     const total1 = cart.reduce((summ, item) => {
    //         return summ + item.num
    //     },0)
    //     window.localStorage.setItem('total_2v', total1)
    //     this.reload()
    // },
    // 提交订单
    goOrder() {
      this.detailList.num = 1
      window.localStorage.setItem(
        'orderCart_2v',
        JSON.stringify([this.detailList])
      )
      this.$router.push('/order')
    },
    // 幻灯片改变的事件 
    carouselchange(i) {
      this.isflow = i
    }
  }
}
</script>
<style lang="less" scoped>
.detail_box {
  width: 100%;
  background-color: #f0f0f0;
  padding-bottom: 60px;
  .detail_box1 {
    .tt {
      display: flex;
      line-height: 56px;
      font-size: 14px;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
      }
      span:nth-child(2) {
        color: #999;
      }
    }
    .detail_box_cneter {
      display: flex;
      .lf {
        width: 232px;
        // height: 1015px;
        background-color: #fff;
        padding: 0 10px 10px;
        margin-right: 20px;
        .jingxuan_tt {
          font-size: 18px;
          font-weight: 700;
          line-height: 60px;
        }
        .goods_box {
          border: 1px solid #fff;
          margin-bottom: 4px;
          img {
            width: 230px;
            height: 230px;
            display: block;
            
          }
          .tt_rong {
            font-size: 14px;
            font-weight: 700;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 10px 5px 6px;
          }
          .money {
            font-size: 14px;
            padding-left: 5px;
            padding-bottom: 20px;
            color: #e85050;
            span {
              font-size: 18px;
              font-weight: 700;
            }
          }
        }
        .goods_box:hover {
          cursor: pointer;
          box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.16);
          transform: scale(1.02, 1.02);
         border: 1px solid #E85050;
        }
      }
      .rt {
        width: 927px;
        .rt_top {
          display: flex;
          background-color: #fff;
          padding: 30px 20px 20px;
          margin-bottom: 20px;
          .rt_top_lt {
            margin-right: 20px;
            .goods_imgbox {
              width: 400px;
              height: 400px;
              border: 1px solid #eee;
              margin-bottom: 20px;

              .goods_img {
                // display: block;
                width: 100%;
                height: 100%;
              }
            }

            .img_box {
              height: 74px;
              display: flex;
              justify-content: center;
              align-items: center;
              .lt_img,
              .rt_img {
                width: 19px;
                height: 19px;
              }
              .img_lun {
                display: block;
                min-width: 74px;
                max-width: 74px;
                height: 74px;
              }
              .lt_img {
                transform: rotate(180deg);
              }
            }
          }
          .rt_top_rt {
            div {
              margin-bottom: 20px;
              font-weight: 700;
              font-size: 14px;
              span {
                font-weight: 400;
                color: #999;
              }
              .jiage {
                color: #e85050;
                font-weight: 700;
              }
              .shangcheng_tt {
                color: #333;
                font-weight: 700;
                margin: 0 4px;
              }
            }
            .rt_top_rt_name {
              width: 464px;
              font-size: 20px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .rt_top_rt_tt {
              color: #e85050;
              font-weight: 400 !important;
              line-height: 30px;
              height: 60px;
              font-size: 14px;
              word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .bm_btn {
              width: 464px;
              height: 47px;
              background-color: #eee;
              display: flex;
              padding: 20px 0;
              div {
                width: 116px;
                height: 46px;
                text-align: center;
                line-height: 46px;
                color: #fff;
                background-color: #ff8800;
                cursor: pointer;
              }
              div:nth-child(1) {
                background-color: #e85050;
                margin: 0 20px;
              }
            }
          }
        }
        .rt_bm {
          .rt_bm_tab {
            height: 58px;
            background-color: #fff;
            border-bottom: 2px solid #e85050;
            display: flex;
            div {
              width: 110px;
              line-height: 58px;
              text-align: center;
              color: #999;
              cursor: pointer;
            }
            .rt_active {
              background-color: #e85050;
              color: #fff;
            }
          }
          .img_content {
            width: 100%;
            // height: 1100px;
            // overflow-y: scroll;
            background-color: #fff;
            table {
              padding: 20px 20px 50px;
            }
            tr {
              width: 887px;
            }
            td {
              border: 1px solid #f0f0f0;
              padding: 10px 20px;
            }
            td:first-child {
              background-color: #f9f9f9;
              text-align: right;
            }
          }
        }
      }
    }
  }
  .el-carousel__item--card {
    width: 74px!important;
  }
}
</style>

