<template>
  <div>
    <div class="welecom_tt">
      <div class="content width">
        <div class="left">
          <div>欢迎您来到{{siteList.company_name}}</div>
          <div @click="login">请登录</div>
          <div></div>
          <div @click="registered">免费注册</div>
        </div>
        <div class="right">
          <div @click="goCart">我的购物车</div>
          <div @click="shoucang">收藏本站</div>
          <div>服务热线：{{siteList.phone}}</div>
        </div>
      </div>
    </div>
    <!-- 头部 -->
    <div class="header">
      <div class="content">
        <div class="header_box">
          <img class="logo_img" :src="URL + imgUrl" alt="" />
          <!-- <img v-else class="logo_img" src="../assets/img/logo.png" alt=""> -->

          <div>
            <input
              placeholder="请输入搜索的商品名称"
              type="text"
              v-model.trim="value"
              class="sousuo_input"
              autofocus
            />
            <!-- <div class="resou">
              
            </div> -->
          </div>
          <div class="sousuo_box" @click="sousuo">
            <img
              class="sousuo_img"
              src="../assets/img/icon_serch@2x.png"
              alt=""
            />
            <span>搜索</span>
          </div>
          <img
            class="logo_img1"
            src="../assets/img/icon-zhiyou@2x.png"
            alt=""
          />
          <div class="tt">商家自营</div>
          <img
            class="logo_img1"
            src="../assets/img/icon-zhengpin@2x.png"
            alt=""
          />
          <div class="tt">正品保证</div>
          <img
            class="logo_img1"
            src="../assets/img/icon-shouhouwuyou@2x.png"
            alt=""
          />
          <div class="tt">售后无忧</div>
          <!-- <div class="cart_box" @click="goCart">
            <img class="cart_img" src="../assets/img/icon_shopping@2x.png" alt="">
            <div v-if="+total !== 0" class="cart_num">{{total}}</div>
            <span>我的购物车</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="headerul">
      <div class="content">
        <div class="nav">
          <div class="nav_box">
            <div class="quanbu">全部商品分类</div>
            <ul class="da_ul">
              <li class="li_da" v-for="(item, i) in categoryList" :key="i">
                <span class="span_da" @click="goList(item.id, 1)">
                  <img
                    v-show="i === 0"
                    class="img_nav"
                    src="../assets/img/icon-jisuanji@2x.png"
                    alt=""
                  />
                  <img
                    v-show="i === 1"
                    class="img_nav"
                    src="../assets/img/icon-bangongjiaju@2x.png"
                    alt=""
                  />
                  <img
                    v-show="i === 2"
                    class="img_nav"
                    src="../assets/img/icon-dianqishebei@2x.png"
                    alt=""
                  />
                  <img
                    v-show="i === 3"
                    class="img_nav"
                    src="../assets/img/icon-bangongshebei@2x.png"
                    alt=""
                  />
                  <img
                    v-show="i === 4"
                    class="img_nav"
                    src="../assets/img/bi.png"
                    alt=""
                  />
                  <img
                    v-show="i === 5"
                    class="img_nav"
                    src="../assets/img/shu.png"
                    alt=""
                  />
                  <img
                    v-show="i === 6"
                    class="img_nav"
                    src="../assets/img/ruanjian.png"
                    alt=""
                  />
                  {{ item.catename }}
                </span>
                <ul class="zhong_ul">
                  <li
                    class="li_zhong"
                    v-for="(it, e) in item.childlist"
                    :key="e"
                  >
                    <span class="span1" @click="goList(it.id, 2)"
                      ><div class="span2">{{ it.catename.slice(0, 6) }}</div>
                      <img
                        class="img_icon"
                        src="../assets/img/right.png"
                        alt=""
                    /></span>
                    <ul>
                      <li
                        v-for="(it1, e1) in it.childlist"
                        :key="e1"
                        @click="goList(it1.id, 3)"
                      >
                        {{ it1.catename }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div @click="goWelecom">网站首页</div>
          <!-- <div @click="gotext1">新闻公告</div> -->
          <div
            @click="gonav(item.link_url)"
            v-for="(item, i) in navbarList"
            :key="i"
          >
            {{ item.link_title }}
          </div>
        </div>
      </div>
    </div>
    <!-- 轮播 -->
    <div class="lunbo_box">
      <el-carousel height="500px" :interval="2000" style="z-index: 1">
        <el-carousel-item v-for="(item, i) in bannerList" :key="i">
          <img
            @click="link(item.ad_link)"
            class="img_lunbo"
            :src="URL + item.ad_pic"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>

    <router-view></router-view>
    <!-- 底部木块 -->
    <div class="foot">
      <div class="content foot-box1">
        <div class="foot_top">
          <img src="../assets/img/foot1 (1).png" alt="">
          <span>100%正品品质保证</span>
        </div>
        <div class="foot_top">
          <img src="../assets/img/foot1 (2).png" alt="">
          <span>7天无理由退货</span>
        </div>
        <div class="foot_top">
          <img src="../assets/img/foot1 (3).png" alt="">
          <span>7 x 24 小时客服</span>
        </div>
        <div class="foot_top">
          <img src="../assets/img/foot1 (4).png" alt="">
          <span>政府专属采购</span>
        </div>
        <div class="foot_top">
          <img src="../assets/img/foot1 (5).png" alt="">
          <span>100%品牌厂家直采</span>
        </div>
      </div>
      <div class="content foot_box">
        <ul v-for="(item, i) in bottomList" :key="i">
          <li>{{ item.catename }}</li>
          <li
            class="li"
            @click="goText(it.id, it.catename)"
            v-for="(it, e) in item.childlist"
            :key="e"
          >
            {{ it.catename }}
          </li>
        </ul>
        <div class="foot_bm">
          <div>{{siteList.phone}}</div>
          <div>周一至周五   9:00- 17:00</div>
          <div>(全国服务热线)</div>
          <div>欢迎来电咨询</div>
        </div>
      </div>
    </div>
    <div class="foot1">
      <div class="content foot1_box">
        <div>
          <span>版权所有：{{ siteList.company_name }}</span>
          <span class="dizhi">地址：{{ siteList.address }}</span>
        </div>
        <div class="foot1_bm">
          <div @click="benan" class="ying">备案编号：{{ siteList.beian }}</div>
         
          <div @click="goying" class="ying">营业执照公示</div>
          <div class="img_box" v-if="siteList.police">
            <img class="gongan" src="../assets/img/gongan.png" alt="" />
          <span class="ying" @click="goGongan">{{ siteList.police }}</span>
          </div>
          
          
          <div>电信增值许可证：{{ siteList.telecom }}</div>
          
          <div>邮箱：{{ siteList.email }}</div>
        </div>
      </div>
    </div>
    <!-- 点击回顶部图标 -->
    <div class="scrollTop-wrap">
      <div v-if="btnFlag" id="connectUsImg" class="go-top" @click="backTop()">
        <img src="../assets/img/icon1.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data() {
    return {
      value: '',
      categoryList: [],
      bottomList: [],
      siteList: {},
      navbarList: [],
      bannerList: [],
      imgUrl: '',
      URL: '',
      total: 0,
      btnFlag: false,
    }
  },
  mounted() {
    this.total = window.localStorage.getItem('total_2v')
    this.URL = this.$URL
    this.category()
    this.bottom()
    this.site()
    this.navbar()
    this.banner()
    window.addEventListener('scroll', this.scrollToTop, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop, true)
  },
  methods: {
    //   轮播
    async banner() {
      const { data: res } = await this.$http.get('index.php/index/index/banner')
      if (res.code !== 1) return
      this.bannerList = res.data
    },
    // 商品分类
    async category() {
      const { data: res } = await this.$http.get(
        'index.php/index/index/category'
      )
      if (res.code !== 1) return
      this.categoryList = res.data
    },
    // 底部文章分类
    async bottom() {
      const { data: res } = await this.$http.get('index.php/index/index/bottom')
      if (res.code !== 1) return
      this.bottomList = res.data
    },
    // 网站信息
    async site() {
      const { data: res } = await this.$http.get('index.php/index/setting/site')
      if (res.code !== 1) return
      this.siteList = res.data
      this.imgUrl = res.data.site_logo
      window.document.title = res.data.site_name
      window.sessionStorage.setItem('site_name', res.data.site_name)
      // window.document.title = res.data.site_name ? res.data.site_name : '政采商城'
    },
    // 网站导航
    async navbar() {
      const { data: res } = await this.$http.get(
        'index.php/index/setting/navbar'
      )
      if (res.code !== 1) return
      this.navbarList = res.data
    },
    // 点击网站导航跳转
    gonav(url) {
      window.open(url)
    },
    // 点击跳转到文章详情
    goText(id, catename) {
      let cate = this.siteList.site_name + '-' + catename
      window.sessionStorage.setItem('cate', cate)
      // this.$router.push('/text?id=' + id)
      // window.open(this.URL + '/text?id=' + id)
      // this.reload()
       let routeUrl = this.$router.resolve({
        path: '/text?id=' + id
      });
      window.open(routeUrl.href, '_blank');
    },
    // 点击跳转到商品列表页面
    goList(id, type) {
      // this.$router.push('/list?id=' + id + '&type=' + type)
      let routeUrl = this.$router.resolve({
        path: '/list?id=' + id + '&type=' + type
      });
      window.open(routeUrl.href, '_blank');
      // window.open(this.URL + '/list?id=' + id + '&type=' + type)
      // this.reload()
    },
    // 点击轮播跳转到对应链接

    link(url) {
      console.log(url)
      if (url) {
        window.open(url)
      }
    },
    // 点击搜索,跳转到商品列表
    sousuo() {
      if (this.value == '') return this.$message.error('请输入搜索内容')
      // this.$router.push('/list?name=' + this.value)
      // this.reload()
      let routeUrl = this.$router.resolve({
        path: '/list?name=' + this.value
      });
      window.open(routeUrl.href, '_blank');
    },
    // 点击我的购物车，跳转到购物车页面
    goCart() {
      this.$router.push('/cart')
    },
    // 网站首页
    goWelecom() {
      this.$router.push('/')
    },
    // 登录
    login() {
      this.$router.push('/login')
    },
    // 注册
    registered() {
      this.$router.push('/registered')
    },
    // 收藏
    shoucang() {
      alert('收藏网站失败，请使用Ctrl+D进行添加。')
    },
    // 点击跳转到新闻公告页面
    gotext1() {
      this.$router.push('/text1')
    },

    // 点击跳转到营业执照
    goying() {
      if (this.siteList.license != '') {
        window.open(this.$URL + this.siteList.license)
      }
    },
    // 点击跳转到备案编号
    benan() {
      window.open('https://beian.miit.gov.cn/')
    },
    // 点击公安备案跳转
    goGongan() {
      if (this.siteList.policeUrl != '') {
        window.open(this.siteList.policeUrl)
      }
    },
    // 点击图标回到顶部方法，加计时器是为了缓慢回到顶部
    backTop() {
      const that = this
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5)
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed
        if (that.scrollTop === 0) {
          clearInterval(timer)
        }
      }, 16)
    },
    // 计算距离顶部的高度，当高度大于200显示回顶部图标，小于200则隐藏
    scrollToTop() {
      const that = this
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 60) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.welecom_tt {
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #666666;
  line-height: 40px;
  .width {
    display: flex;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      div:nth-child(2) {
        margin-left: 82px;
        cursor: pointer;
        
      }
      div:nth-child(2):hover {
          color: #e85050;
        }
      div:nth-child(3) {
        height: 19px;
        border-left: 1px solid #707070;
        margin: 0 10px;
      }
      div:nth-child(4) {
        cursor: pointer;
      }
      div:nth-child(4):hover {
          color: #e85050;
        }
    }
    .right {
      display: flex;
      div:nth-child(2) {
        margin: 0 24px;
        cursor: pointer;
      }
      div:nth-child(2):hover {
          color: #e85050;
        }
      div:nth-child(1) {
        cursor: pointer;
      }
      div:nth-child(1):hover {
          color: #e85050;
        }
    }
  }
}
.header {
  width: 100%;
  height: 127px;
  background-color: #fff;
  // padding: 26px 0 0 0;
  .header_box {
    height: 127px;
    align-items: center;
    display: flex;
    .logo_img {
      width: 250px;
      height: 80px;
      margin-right: 17px;
    }
    .sousuo_input {
      width: 531px;
      height: 36px;
      background: #ffffff;
      border: 2px solid #134daf;
    }

    .sousuo_box {
      width: 78px;
      height: 40px;
      background-color: #134daf;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
      margin-left: -80px;
      .sousuo_img {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }
    .logo_img1 {
      width: 36px;
      height: 36px;
      margin: 0 6px 0 20px;
    }
    .tt {
      font-size: 16px;
      color: #666666;
    }
    .cart_box {
      position: relative;
      width: 165px;
      height: 56px;
      background-color: #ff8800;
      // border: 2px solid #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-left: 20px;
      cursor: pointer;
      .cart_img {
        width: 28px;
        height: 28px;
        margin-right: 5px;
      }
      .cart_num {
        position: absolute;
        top: 6px;
        left: 43px;
        font-size: 12px;
        background-color: #e85050;
        color: #fff;
        border-radius: 8px;
        padding: 0px 4px;
      }
    }
  }
}
.headerul {
  width: 100%;
  height: 44px;
  background-color: #134daf;
  .nav {
    display: flex;
    height: 44px;
    line-height: 44px;
    // font-weight: 700;
    div {
      margin-left: 57px;
      color: #fff;
      cursor: pointer;
    }
    div:nth-child(1) {
      margin-left: 0;
      .quanbu {
        width: 212px;
        height: 44px;
        background-color: #03317e;
        color: #fff;
        text-align: center;
      }
    }
  }

  .da_ul {
    // display: none;
    color: #666666;
    z-index: 2;
    position: relative;
    font-weight: 400;
    height: 0px;
    line-height: 20px;
    .li_da {
      display: flex;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
      .span_da {
        display: flex;
        align-items: center;
        height: 55px;
        width: 212px;
        background-color: #fff;
        text-align: center;
        line-height: 55px;
        border-bottom: 1px solid #eee;
        font-size: 14px;
        cursor: pointer;
        .img_nav {
          margin: 0 4px 0 23px;
          width: 30px;
          height: 30px;
        }
      }
      .span_da:hover {
        background-color: #307DFF;
        color: #fff;
      }

      .active {
        background-color: #ccc;
        font-weight: 700;
      }
      .zhong_ul {
        display: none;
        position: absolute;
        top: -1px;
        width: 939px;
        left: 212px;
        padding: 5px 20px 18px 20px;
        color: #818181;
        font-size: 14px;
        background-color: #fff;
        border: 1px solid #134daf;
        height: 369px;
      }
    }
    .li_da:hover .zhong_ul {
      display: block;
    }
    .li_zhong {
      display: flex;
      padding: 4px;
      border-bottom: 1px solid #e7e6e6;
      .span1 {
        display: flex;
        align-items: center;
        padding: 0 0 0 20px;
        // min-width: 120px;
        // max-width: 120px;
        .span2 {
          width: 89px;
          height: 32px;
          background-color: #e85050;
          font-size: 14px;
          color: #fff;
          line-height: 32px;
          padding: 0 5px;
        }
        .img_icon {
          width: 32px;
          height: 32px;
        }
      }
      .span1:hover {
        color: #e85050;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          height: 20px;
          border-left: 1px solid #e7e6e6;
          padding: 0 30px;
          margin: 5px 0;
        }
        li:hover {
          color: #e85050;
        }
      }
    }
  }
  .nav_box:hover .da_ul {
    display: block;
  }
}
// 轮播
.lunbo_box {
  .img_lunbo {
    width: 100%;
    height: 500px;
    cursor: pointer;
  }
}


// 底部
.foot {
  width: 100%;
  height: 220px;
  background-color: #262626;
  color: #fff;
  .foot-box1 {
    width: 1080px;
    display: flex;
    justify-content: space-between;
    padding: 20px 60px;
    .foot_top {
      display: inline-flex;
      align-items: center;
      color: #FCFCFC;
      img {
        width: 42px;
        height: 42px;
        display: block;
        margin-right: 6px;
      }
    }
  }
  .foot_box {
    display: flex;
    justify-content: center;
    font-size: 14px;
    ul {
      width: 220px;
      text-align: center;
      
      .li {
        padding-top: 11px;
        cursor: pointer;
      }
      li:nth-child(1) {
        line-height: 15px;
        font-weight: 700;
        margin-top: 5px;
      }
      .li:hover {
        color: #e85050;
      }
    }
    .foot_bm {
      margin-left: 30px;
      text-align: center;
      div:nth-child(1) {
        
        font-size: 22px;
        margin-bottom: 4px;
      }
      div:nth-child(3) {
        padding: 1px 0 5px;
      }
      div:nth-child(4) {
        width: 134px;
        height: 32px;
        background: #E85050;
        line-height: 32px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}
.foot1 {
  width: 100%;
  height: 51px;
  background-color: #141414;
  padding: 10px 0;
  
  .foot1_box {
    font-size: 14px;
    color: #717171;
    .dizhi {
      margin-left: 200px;
    }
    .foot1_bm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      .img_box {
        display: flex;
        align-items: center;
      }
      div:nth-child(even) {
        margin: 0 5px;
      }
      .ying:hover {
        cursor: pointer;
        color: #e85050;
      }
      .gongan {
        display: block;
        width: 20px;
        height: 20px;
      }
    }
  }
}
.go-top {
  position: fixed;
  bottom: 150px;
  right: 60px;
  z-index: 15;
  cursor: pointer;
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
