import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'


// 导入全局样式表
import './assets/css/global.css'

import axios from 'axios'
// 配置请求根路径
// http://zcbus.com/
// axios.defaults.baseURL = 'http://zcnew.com/'
// axios.defaults.baseURL = 'https://testzc2.zzyzwl.com/'
// axios.defaults.baseURL = 'https://zc2.zzyzwl.com/'
axios.defaults.baseURL = window.location.protocol + '//' + window.location.host




axios.interceptors.request.use(config => {
  // config.headers.Authorization = window.localStorage.getItem('token')
  return config
})
// Vue.prototype.$URL = 'http://zcnew.com'
Vue.prototype.$URL = 'https://testzc2.zzyzwl.com'
// Vue.prototype.$URL = 'https://zc2.zzyzwl.com'





Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
  // mounted () {
  //   document.dispatchEvent(new Event('render-event'))
  // }
}).$mount('#app')
