import Vue from 'vue'
import { Button, Input, Carousel, CarouselItem, InputNumber, Form, FormItem, Pagination, Message  } from 'element-ui'

Vue.use(Button)
Vue.use(Input)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(InputNumber)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Pagination)

Vue.prototype.$message = Message



