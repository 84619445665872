<template>
    <div class="success_box">
        <div class="content success_box1">
            <div class="tt">
                <span>当前位置：</span>
                <!-- <span>我的购物车</span>
                <img src="../assets/img/icon_inter@2x.png" alt=""> -->
                <span>成功提交订单</span>
            </div>
            <div class="box">
                <div class="box_tt">成功提交订单</div>
                <div class="box_bg">
                    <img class="box_bg_img" src="../assets/img/icon_cg@2x.png" alt="">
                    <div class="box_bg_tt">恭喜您，成功提交订单!</div>
                </div>
                <div class="box_list">
                    <div class="box_list_top">
                        <div>订单编号:<span>{{dataList.no}}</span></div>
                        <div>配送方式:<span>商家配送</span></div>
                    </div>
                    <div class="box_list_top">
                        <div>应付金额:<span class="money">￥{{dataList.money}}</span></div>
                        <div>支付方式:<span>货到付款</span></div>
                    </div>
                </div>
                <div class="btn" @click="goWelecom">返回首页</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
        dataList: {}
    }
  },
  mounted() {
      window.document.title = window.sessionStorage.getItem('site_name')
      this.dataList = JSON.parse(window.localStorage.getItem('success_2v'))
  },
  methods: {
    //   点击返回首页
    goWelecom() {
        this.$router.push('/')
    }
  }
}
</script>
<style lang="less" scoped>
.success_box {
  background-color: #f0f0f0;
  width: 100%;
  padding-bottom: 60px;
  .success_box1 {
    .tt {
      display: flex;
      line-height: 56px;
      font-size: 14px;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
      }
      span:nth-child(3) {
        color: #999;
      }
    }
    .box {
        background-color: #fff;
        padding: 0 20px 20px;
        .box_tt {
            font-weight: 700;
            line-height: 70px;
        }
        .box_bg {
            height: 127px;
            background: #eee;
            display: flex;
            justify-content: center;
            align-items: center;
            .box_bg_img {
                display: block;
                width: 44px;
                height: 44px;
            }
            .box_bg_tt {
                font-size: 18px;
                font-weight: 700;
                margin-left: 10px;
            }
        }
        .box_list {
            width: 500px;
            margin: 0 auto;
            color: #999;
            .box_list_top {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                span {
                    font-size: 14px;
                    color: #333;
                    margin-left: 10px;
                }
                .money {
                    color: #e85050;
                }
            }
        }
        .btn {
            width: 120px;
            height: 40px;
            border: 1px solid #999;
            margin: 60px auto 20px;
            text-align: center;
            line-height: 40px;

            cursor: pointer;
        }
    }
  }
}
</style>
