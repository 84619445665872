<template>
    <div>
        <!-- 列表部分 -->
        <div class="list_box">
            <div class="content list_box1">
                <div class="list_tab">
                    <div :class="isflow ? 'list_tab_box list_tab_box1' : 'list_tab_box'" v-if="brands.length > 0">
                      <div class="gengduo" @click="gengduo">
                              <img src="../assets/img/shangxia.png" alt="">
                            </div>
                        <span class="list_tab_tt">品牌：</span>
                        <div class="list_tab_rt">
                            <span
                                :class="brand_id == item.id ? 'span active' : 'span'"
                                @click="pinpai(item.id)"
                                v-for="(item, i) in brands"
                                :key="i"
                            >{{item.brand_name}}</span>     
                        </div>
                    </div>
                    <div class="list_tab_box">
                        <span class="list_tab_tt">价格(元)：</span>
                        <div class="list_tab_rt">
                            
                            <span
                                :class="price == it ? 'span active' : 'span'"
                                @click="jiage(it)"
                                v-for="(it, e) in prices"
                                :key="e"
                            >{{it}}</span>
                        </div>
                    </div>
                </div>
                <div class="goods_list">
                    <div @click="goDetail(item.goods_xh)" class="goods_list_box" v-for="(item,i) in itemsList" :key="i">
                        <img class="goods_list_img" :src="URL + item.cover_img" alt="">
                        <div class="goods_tt">{{item.goods_name}}</div>
                        <div class="money">
                          <div>
                            ￥
                          <span>{{item.shop_price}}</span>
                          </div>
                          
                          <div class="hot_bm_rt">立即购买</div>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="offset"
                        :page-sizes="[10, 20, 40,50]"
                        :page-size="limit"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      itemsList: [],
      brand_id: '',
      price: '',
      offset: 1,
      limit: 10,
      total: 0,
      URL: '',
        id: '',
        type: '',
        name: '',
      prices: [],
      // 品牌
      brands: [],
      isflow: true
    }
  },
  mounted() {
    window.document.title = window.sessionStorage.getItem('site_name')
    this.URL = this.$URL
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.name = this.$route.query.name
    this.items()
    // this.filter()
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.items()
    },
    handleCurrentChange(val) {
      this.offset = val
      this.items()
    },
    //   列表搜索项
    // async filter() {
    //   const { data: res } = await this.$http.get('index.php/index/goods/filter', {
    //     params: {
    //       cat_id: this.id,
    //       type: this.type
    //     }
    //   })
    //   if (res.code !== 1) return
    //   this.filterList = res.data
    //   this.brands = res.data.brands
    // },
  // 更多
    gengduo() {
      this.isflow = !this.isflow
    },
    // 商品列表
    async items() {
      const { data: res } = await this.$http.post('index.php/index/goods/items', {
        filter: {
          category_id: this.id,
          brand_id: this.brand_id,
          price: this.price,
          name: this.name
        },
        type: this.type,
        page: {
          offset: this.offset,
          limit: this.limit
        }
      })
      if (res.code !== 1) return
      this.itemsList = res.data.data
      this.total = res.data.total
      this.brands = res.data.brands
      this.prices = res.data.prices
      // if(this.total == 0) return this.$message.error('暂无商品！')
    },
    // 点击品牌查询商品列表
    pinpai(brand_id) {
      this.brand_id = brand_id
      this.items()
    },
    // 点击价格查询商品列表
    jiage(price) {
      this.price = price
      this.items()
    },
    // 点击跳转到商品详情页
    goDetail(id) {
        // this.$router.push('/detail?id=' + id)
         let routeUrl = this.$router.resolve({
        path: '/detail?id=' + id
      });
      window.open(routeUrl.href, '_blank');
    }
  }
}
</script>
<style lang="less" scoped>
// 轮播
.lunbo_box {
  .img_lunbo {
    width: 100%;
    height: 500px;
  }
}
// 列表部分
.list_box {
  width: 100%;
  background-color: #f0f0f0;
  overflow: hidden;
  padding-bottom: 60px;
  .list_box1 {
    .list_tab {
      background-color: #fff;
      margin: 20px 0;
      .list_tab_box1 {
        height: 61px;
        overflow: hidden;
      }
      .list_tab_box {
        //    line-height: 40px;
        display: flex;
        padding: 10px 30px 10px 20px;
        box-sizing: border-box;
        position: relative;
        .gengduo {
            cursor: pointer;
            position: absolute;
            top: 15px;
            right: 10px;
            width: 30px;
            height: 30px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        .list_tab_tt {
          font-weight: 700;
          min-width: 80px;
          color: #666;
          padding: 10px 10px;
        }
        .list_tab_rt {
          display: flex;
          flex-wrap: wrap;
          
          
        }
        .span {
          color: #818181;
          padding: 10px 10px;
          cursor: pointer;
        }
        .span:hover {
          color: #e85050;
        }
        .active {

            color: #e85050;
        }
      }
    }
    .goods_list {
      display: flex;
      flex-wrap: wrap;
      .goods_list_box {
        width: 230px;
        height: 322px;
        background-color: #fff;
        border: 1px solid #fff;
        font-size: 14px;
        color: #333;
        margin: 0 9px 10px 0;
        .goods_list_img {
          display: block;
          width: 230px;
          height: 230px;
        }
        .goods_list_img:hover {
          // transform: scale(1.01)
        }
        .goods_tt {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 5px;
          padding: 10px;
        }
        .money {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          color: #e85050;
          font-weight: 700;
          padding: 6px 10px 0;
          span {
            font-size: 18px;
          }
          .hot_bm_rt {
          width: 80px;
          height: 34px;
          background-color: #e85050;
          font-size: 14px;
          color: #fff;
          text-align: center;
          line-height: 34px;
        }
        }
      }
      .goods_list_box:hover {
        cursor: pointer;
        border: 1px solid #E85050;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
        transform: scale(1.02, 1.02);
      }
      .goods_list_box:nth-child(5n) {
        margin: 0 0 10px 0;
      }
    }
    .pagination {
      width: 100%;
      text-align: center;
      padding-top: 30px;
    }
  }
}
</style>
