import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './components/Home.vue'
import Welecom from './components/Welecom.vue'
import Detail from './components/Detail.vue'
import List from './components/List.vue'
import Cart from './components/Cart.vue'
import Order from './components/Order.vue'
import Success from './components/OrderSuccess.vue'
import Text from './components/Text.vue'
import Text1 from './components/Text1.vue'
import Login from './components/Login.vue'
import Registered from './components/Registered.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/', component: Home, redirect: '/home',
        children: [
            { path: '/home', component: Welecom },
            { path: '/detail', component: Detail },
            { path: '/list', component: List },
            { path: '/cart', component: Cart },
            { path: '/order', component: Order },
            { path: '/success', component: Success},
            { path: '/text', component: Text},
            { path: '/text1', component: Text1},
            { path: '/login', component: Login},
            { path: '/registered', component: Registered}
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
