<template>
    <div class="cart_box">
        <div class="content cart_box1">
            <div class="tt">
                <span>当前位置：</span>
                <!-- <span>商品详情</span> -->
                <!-- <img src="../assets/img/icon_inter@2x.png" alt=""> -->
                <span>我的购物车</span>
            </div>
            <div class="cart_list">
                <div class="tt1">
                    <span>商品名称</span>
                    <span>单价(元)</span>
                    <span>数量</span>
                    <span>小计</span>
                    <span>操作</span>
                </div>
                <div class="goods" v-for="(item,i) in cart" :key="i">
                    <img @click="checkout_dan(item.id)" v-if="item.is_checkout" class="checkout_img" src="../assets/img/icon_qx@2x.png" alt="">
                    <img @click="checkout_dan(item.id)" v-else class="checkout_img" src="../assets/img/icon_buxian@2x.png" alt="">
                    <img class="goods_img" :src="URL + item.cover_img" alt="">
                    <div class="goods_tt">{{item.goods_name}}</div>
                    <div class="goods_money">￥{{item.shop_price}}</div>
                    <div class="goods_num">
                        <el-input-number @change="handleChange(item, i)" size="small" v-model="item.num" :min="1" :max="item.goods_num"></el-input-number>
                    </div>
                    <div class="goods_money1">￥{{+item.shop_price * item.num}}</div>
                    <div class="goods_del" @click="del(i)">删除</div>
                </div>
               
                <div class="cart_bm">
                    <div class="cart_bm_lt" @click="quanxuan">
                        <img v-if="isCheckBOX" class="checkout_img1" src="../assets/img/icon_qx@2x.png" alt="">
                        <img v-else class="checkout_img1" src="../assets/img/icon_buxian@2x.png" alt="">
                        <span>全选</span>
                    </div>
                    <div class="cart_bm_rt">
                        <div>已选择<span class="cart_bm_rt_num">{{total}}</span>件商品</div>
                        <div>总计<span class="cart_bm_rt_num1">￥</span><span class="cart_bm_rt_money">{{Amount}}</span></div>
                        <div class="cart_bm_rt_btn" @click="goOrder">去结算</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    inject: ['reload'],
  data() {
    return {
        // 全选
        isCheckBOX: false,
        cart: [],
        URL: '',
        total: 0,
        Amount: 0,
        total2: 0
    }
  },
  mounted() {
      window.document.title = window.sessionStorage.getItem('site_name')
      this.URL = this.$URL
      this.cart = JSON.parse(window.localStorage.getItem('cart_2v'))
      this.cart.forEach(it => {
          it.is_checkout = false
      })
      console.log(this.cart)
  },
  methods: {
    //   单选
      checkout_dan(id) {
          console.log(id)
          this.cart.forEach((it, i) => {
              if(it.id === id) {
                  it.is_checkout = !it.is_checkout
                  this.$set(this.cart, i ,it)
              }
              
          })
          const hasUnCheck = this.cart.some(it => !it.is_checkout)
          this.isCheckBOX = !hasUnCheck
          this._updateCart(this.cart)
          this._updateCart1(this.cart)
      },
    //   全选、不全选
    quanxuan() {
        const hasUnCheck = this.cart.some(it => !it.is_checkout)
        this.cart = this.cart.map(it => {
            it.is_checkout = hasUnCheck
            return it
        })
        this.isCheckBOX = hasUnCheck
        this._updateCart(this.cart)
        this._updateCart1(this.cart)
    },
    // 计数器
    handleChange(item, i) {
        console.log(item, i)
        this._updateCart(this.cart)
        this._updateCart1(this.cart)
    },
    // 总价
    _updateCart(cart) {
        this.Amount = cart.reduce((summ, item) => {
            return summ + (item.is_checkout ? +item.shop_price * item.num : 0)
        },0)
        
    },
    // 选中总件数 
     _updateCart1(cart) {
        this.total = cart.reduce((summ, item) => {
            return summ + (item.is_checkout ? item.num : 0)
        },0)
        
    },
    // 总件数 
    //  _updateCart2(cart) {

    //     const total1 = cart.reduce((summ, item) => {
    //         return summ + item.num
    //     },0)
    //     window.localStorage.setItem('total_2v', total1)
    //     this.reload()
    // },
    
    // 删除
    del(i) {
        this.cart.splice(i, 1)
        window.localStorage.setItem('cart_2v', JSON.stringify(this.cart))
        // this._updateCart2(this.cart)
        
    },
    // 点击去结算，跳转到订单页面
    goOrder() {
        const orderCart = this.cart.filter(it => it.is_checkout)
        if(orderCart.length <= 0) return this.$message.error('请勾选商品！')
        const cart = this.cart.filter(it => !it.is_checkout)
        window.localStorage.setItem('orderCart_2v',JSON.stringify(orderCart))
        window.localStorage.setItem('cart_2v', JSON.stringify(cart))
        // this._updateCart2(cart)
        this.$router.push('/order')
    }

  }
}
</script>
<style lang="less" scoped>
.cart_box {
  background-color: #f0f0f0;
  width: 100%;
  padding-bottom: 60px;
  .cart_box1 {
    .tt {
      display: flex;
      line-height: 56px;
      font-size: 14px;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
      }
      span:nth-child(3) {
        color: #999;
      }
    }
    .cart_list {
        background-color: #fff;
        padding: 40px 20px 30px;
        .tt1 {
            line-height: 46px;
            border: 1px solid #eee;
            font-size: 14px;
            font-weight: 700;
            span:nth-child(1) {
                margin-left: 50px;
            }
            span:nth-child(2) {
                margin-left: 580px;
            }
            span:nth-child(3) {
                margin-left: 110px;
            }
            span:nth-child(4) {
                margin-left: 110px;
            }
            span:nth-child(5) {
                margin-left: 82px;
            }
        }
        .goods {
            border: 1px solid #eee;
            font-size: 14px;
            height: 174px;
            display: flex;
            align-items: center;
            margin-top: 20px;
            .checkout_img {
                display: block;
                width: 20px;
                height: 20px;
                padding: 10px 20px;
            }
            .goods_img {
                width: 140px;
                height: 140px;
                border: 1px solid #eee;
            }
            .goods_tt {
                width: 380px;
                height: 150px;
                font-weight: 700;
                line-height: 30px;
                margin-left: 20px;
            }
            .goods_money {
                height: 140px;
                width: 85px;
                margin-left: 80px;
            }
            .goods_num {
                height: 150px;
                margin-left: 40px;
            }
            .goods_money1 {
                height: 140px;
                margin-left: 40px;
                width: 85px;
            }
            .goods_del {
                height: 140px;
                margin-left: 35px;
            }
        }
        .cart_bm {
            height: 44px;
            border: 1px solid #eee;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            margin: 30px 0;
            .cart_bm_lt {
                display: flex;
                align-items: center;
                .checkout_img1 {
                    display: block;
                    width: 20px;
                    height: 20px;
                    padding: 10px 20px;
                }
            }
            .cart_bm_rt {
                display: flex;
                align-items: center;
                div:nth-child(2) {
                    margin: 0 20px;
                }
                .cart_bm_rt_num {
                    color: #e85050;
                    margin: 0 10px;
                }
                .cart_bm_rt_num1 {
                    color: #e85050;
                }
                .cart_bm_rt_money {
                    color: #e85050;
                    font-weight: 700;
                    margin-left: 5px;
                }
                .cart_bm_rt_btn {
                    width: 90px;
                    height: 46px;
                    background-color: #e85050;
                    color: #fff;
                    text-align: center;
                    line-height: 46px;
                    cursor: pointer;
                }
            }
        }
    }
  }
}
</style>
