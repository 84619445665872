<template>
    <div class="text_box">
        <!-- <div class="img_box">
            <img src="../assets/img/lunbo.png" alt="">
        </div> -->
        <div class="content text_box1">
            <div class="detail">
                <div v-if="showList" class="tt2">{{showList.title}}</div>
                <div v-if="showList" v-html="showList.content"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      showList: {}
    }
  },
  mounted() {
    window.document.title = window.sessionStorage.getItem('cate') || '政采商城'
    const a = this.$route.query.a || 0
    console.log(a)
    if(a == 1) {
      this.detail(this.$route.query.id)
    }else {
      this.show(this.$route.query.id)
    }
    
  },
  methods: {
    // 底部详情
    async show(id) {
      const { data: res } = await this.$http.get('index.php/index/post/show', {
        params: {
          cat_id: id
        }
      })
      if (res.code !== 1) return
      this.showList = res.data
     
    },
    // 新闻详情
    async detail(id) {
      
      const { data: res } = await this.$http.get('index.php/index/post/detail', {
        params: {
          post_id: id
        }
      })
      if (res.code !== 1) return
      this.showList = res.data
      
    },
  }
}
</script>
<style lang="less" scoped>
.text_box {
  width: 100%;
  background-color: #f0f0f0;
  padding-bottom: 60px;
  overflow: hidden;
  .img_box {
    width: 100%;
    height: 356px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text_box1 {
    display: flex;
    background-color: #fff;
    padding: 30px 20px;
    margin-top: 20px;

    .detail {
        .tt2 {
            font-size: 18px;
            font-weight: 700;
        }
    }
  }
}
</style>

