<template>
  <div class="box">
    <div class="content login">
      <div class="top">
        <div>新用户注册</div>
        <!-- <div class="top_rt">
          <div>如未注册，点此</div>
          <div>注册</div>
        </div> -->
      </div>
      <div class="zhong">
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/ren.png" alt="" />
            账号
          </div>
          <input class="intlogin" v-model="user" type="text" placeholder="请输入您的账号" />
        </div>
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/mi.png" alt="" />
            密码
          </div>
          <input class="intlogin" type="password" v-model="password" placeholder="请输入您的密码" />
        </div>
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/mi.png" alt="" />
            密码
          </div>
          <input class="intlogin" type="password" v-model="password1" placeholder="请再次输入您的密码" />
        </div>
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/shouji.png" alt="" />
            手机号
          </div>
          <input class="intlogin" type="text" v-model="phone" placeholder="请输入您的手机号" />
        </div>
        <div class="user1">
            <img class="img1" src="../assets/img/icon_qx@2x.png" alt="" />
            <div class="box_xieyi">
                  我已看过并接受《<span>用户协议</span>》
                </div>
        </div>
        <div class="denglu" @click="login1">同意协议并注册</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            user: '',
            password: '',
            password1: '',
            phone: ''
        }
    },
    methods: {
        login1() {
            if(this.user === '' || this.password === '' || this.password1 === '' || this.phone === '') {
                return this.$message.error('请您填写完整数据')
            }else {
                return this.$message.warning('此功能尚未开通，请联系客服人员')
            }
        }
    }
}
</script>
<style lang="less" scoped>

.box {
  width: 100%;
  height: 790px;
  background-color: #f0f0f0;
  padding-top: 46px;
  box-sizing: border-box;
  .login {
    height: 644px;
    background-color: #fff;
    border-radius: 12px;
    .top {
      width: 100%;
      height: 61px;
      border-bottom: 1px solid #ddd;
      text-align: center;
      line-height: 61px;
      position: relative;
      font-size: 20px;
      color: #134daf;
      .top_rt {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #666;
        div:nth-child(2) {
          width: 65px;
          height: 36px;
          background: #134daf;
          border-radius: 4px;
          line-height: 36px;
          color: #fff;
          margin:0 20px 0 10px;
        }
      }
    }
    .zhong {
        width: 480px;
        margin: 73px auto 0;
        .user {
            display: flex;
            width: 480px;
            height: 50px;
            border: 1px solid #ddd;
            margin-bottom: 30px;
            .img_box {
                display: flex;
                width: 94px;
                border-right: 1px solid #ddd;
                align-items: center;
                font-size: 16px;
                color: #333;
                img {
                    width: 26px;
                    height: 26px;
                    display: block;
                    margin: 0 6px;
                }
                
            }
            .intlogin {
                width: 350px;
                border: none;
            }
            
        }
        .user1 {
            display: flex;
            margin-top: -15px;
            .img1 {
                width: 20px;
                height: 20px;
                display: block;
                margin-right: 10px;
            }
            .box_xieyi {
                color: #333;
                span {
                    color: #134DAF;
                }
            }
        }
        .denglu {
            width: 480px;
            height: 50px;
            background: #E85050;
            border: 1px solid #E50E0E;
            border-radius: 3px;
            line-height: 51px;
            text-align: center;
            color: #FFFFFF;
            margin-top: 35px;
            cursor: pointer;
        }
    }
  }
}
</style>
