<template>
  <div class="box">
    <div class="content login">
      <div class="top">
        <div>会员登录</div>
        <div class="top_rt">
          <div>如未注册，点此</div>
          <div @click="registered">注册</div>
        </div>
      </div>
      <div class="zhong">
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/ren.png" alt="" />
            账号
          </div>
          <input class="intlogin" v-model="user" type="text" placeholder="请输入您的账号" />
        </div>
        <div class="user">
          <div class="img_box">
            <img src="../assets/img/mi.png" alt="" />
            密码
          </div>
          <input class="intlogin" type="password" v-model="password" placeholder="请输入您的密码" />
        </div>
        <div class="denglu" @click="login1">登录</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    data() {
        return {
            user: '',
            password: ''
        }
    },
    methods: {
        login1() {
            if(this.user === '' || this.password === '') {
                return this.$message.error('请您填写完整数据')
            }else {
                return this.$message.warning('此功能尚未开通，请联系客服人员')
            }
        },
        // 注册
    registered() {
      this.$router.push('/registered')
    },
    }
}
</script>
<style lang="less" scoped>

.box {
  width: 100%;
  height: 634px;
  background-color: #f0f0f0;
  padding-top: 46px;
  box-sizing: border-box;
  .login {
    height: 500px;
    background-color: #fff;
    border-radius: 12px;
    .top {
      width: 100%;
      height: 61px;
      border-bottom: 1px solid #ddd;
      text-align: center;
      line-height: 61px;
      position: relative;
      font-size: 20px;
      color: #134daf;
      .top_rt {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #666;
        div:nth-child(2) {
          width: 65px;
          height: 36px;
          background: #134daf;
          border-radius: 4px;
          line-height: 36px;
          color: #fff;
          margin:0 20px 0 10px;
        }
      }
    }
    .zhong {
        width: 480px;
        margin: 73px auto 0;
        .user {
            display: flex;
            width: 480px;
            height: 50px;
            border: 1px solid #ddd;
            margin-bottom: 30px;
            .img_box {
                display: flex;
                width: 94px;
                border-right: 1px solid #ddd;
                align-items: center;
                font-size: 16px;
                color: #333;
                img {
                    width: 26px;
                    height: 26px;
                    display: block;
                    margin: 0 6px;
                }
                
            }
            .intlogin {
                width: 350px;
                border: none;
            }
        }
        .denglu {
            width: 480px;
            height: 50px;
            background: #E85050;
            border: 1px solid #E50E0E;
            border-radius: 3px;
            line-height: 51px;
            text-align: center;
            color: #FFFFFF;
            margin-top: 70px;
            cursor: pointer;
        }
    }
  }
}
</style>
