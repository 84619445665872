<template>
  <div class="order_box">
    <div class="content order_box1">
      <div class="tt">
        <span>当前位置：</span>
        <!-- <span>我的购物车</span>
        <img src="../assets/img/icon_inter@2x.png" alt="">-->
        <span>填写并核对订单</span>
      </div>
      <div class="goods1">
        <div class="goods_tt1">填写并核对订单</div>
        <div class="goods_box">
          <div class="goods_box_tt">收货人信息</div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
            <el-form-item label="姓名：" prop="receive_name">
              <el-input size="small" class="int" v-model.trim="ruleForm.receive_name"></el-input>
            </el-form-item>
            <el-form-item label="手机号：" prop="receive_phone">
              <el-input size="small" class="int" v-model.trim="ruleForm.receive_phone"></el-input>
            </el-form-item>
            <el-form-item label="详细地址：" prop="receive_address">
              <el-input size="small" class="int1" v-model.trim="ruleForm.receive_address"></el-input>
            </el-form-item>
          </el-form>
          <div class="goods_box_tt">支付方式</div>
          <div class="goods_box_fangshi">货到付款</div>
          <div class="goods_box_tt">配送方式</div>
          <div class="goods_box_fangshi">商家配送</div>
          <div class="goods_box_tt">商品信息</div>
          <div class="tt1">
            <span>商品名称</span>
            <span>单价(元)</span>
            <span>数量</span>
            <span>小计</span>
            <!-- <span>操作</span> -->
          </div>
          <div class="goods" v-for="(item,i) in orderList" :key="i">
            <img class="goods_img" :src="URL + item.cover_img" alt="">
            <div class="goods_tt">{{item.goods_name}}</div>
            <div class="goods_money">￥{{item.shop_price}}</div>
            <!-- <div class="goods_num">{{item.num}}</div> -->
            <div class="goods_num">
              <el-input-number
                @change="handleChange(item, i)"
                size="small"
                v-model="item.num"
                :min="1"
                :max="item.goods_num"
              ></el-input-number>
            </div>
            <div class="goods_money1">￥{{+item.shop_price * item.num}}</div>
            <!-- <div class="goods_del">删除</div> -->
          </div>
          <div class="box1">
            <div>
              已经选择
              <span>{{total}}</span>件商品，总商品金额 ：
              <span>￥{{Amount}}</span>
            </div>
          </div>
          <div class="box1 box2">
            <!-- <div>运费：0</div> -->
          </div>
          <div class="box3">
            <div>应付总额：￥{{Amount}}</div>
          </div>
          <div class="box4">
            <div @click="goCart">返回购物车</div>
            <div @click="tijaio">提交订单</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[3456789]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      cb(new Error('请输入合法的手机号'))
    }
    return {
      orderList: [],
      URL: '',
      Amount: 0,
      total: 0,
      ruleForm: {
        receive_name: '',
        receive_phone: '',
        receive_address: ''
      },
      rules: {
        receive_name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        receive_phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ],
        receive_address: [
          { required: true, message: '请输入收货地址', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
    window.document.title = window.sessionStorage.getItem('site_name')
    this.URL = this.$URL
    this.orderList = JSON.parse(window.localStorage.getItem('orderCart_2v'))
    console.log(1111, this.orderList)
    this._updateCart(this.orderList)
    this._updateCart1(this.orderList)
  },
  methods: {
    // 总价
    _updateCart(cart) {
      this.Amount = cart.reduce((summ, item) => {
        return summ + +item.shop_price * item.num
      }, 0)
    },
    // 总件数
    _updateCart1(cart) {
      this.total = cart.reduce((summ, item) => {
        return summ + item.num
      }, 0)
    },
    // 计数器
    handleChange(item, i) {
        console.log(item, i)
        this._updateCart(this.orderList)
        this._updateCart1(this.orderList)
    },
    // 提交订单
    tijaio() {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) return this.$message.error('请填写完整的数据！')
        const goods_info = []
        this.orderList.forEach(it => {
          goods_info.push({
            goods_id: it.id,
            goods_number: it.num
          })
        })
        const { data: res } = await this.$http.post(
          'index.php/index/order/submit',
          {
            goods_info: goods_info,
            receive_name: this.ruleForm.receive_name,
            receive_phone: this.ruleForm.receive_phone,
            receive_address: this.ruleForm.receive_address
          }
        )
        if (res.code !== 1) return this.$message.error(res.msg)
        this.$refs.ruleForm.resetFields()
        window.localStorage.removeItem('orderCart_2v')
        window.localStorage.setItem('success_2v', JSON.stringify(res.data))
        this.$router.push('/success')
      })
    },
    // 返回购物车
    goCart() {
      this.$router.push('/cart')
    }
  }
}
</script>
<style lang="less" scoped>
.order_box {
  background-color: #f0f0f0;
  width: 100%;
  padding-bottom: 60px;
  .order_box1 {
    .tt {
      display: flex;
      line-height: 56px;
      font-size: 14px;
      align-items: center;
      img {
        width: 19px;
        height: 19px;
      }
      span:nth-child(3) {
        color: #999;
      }
    }
    .goods1 {
      background-color: #fff;
      padding: 0 20px 30px;
      .goods_tt1 {
        font-weight: 700;
        line-height: 78px;
      }
      .goods_box {
        border: 1px solid #eee;
        padding: 20px;
        .goods_box_tt {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
        }
        .int {
          width: 220px;
        }
        .int1 {
          width: 440px;
        }
        .goods_box_fangshi {
          width: 77px;
          height: 30px;
          background-color: #eee;
          color: #666;
          font-size: 14px;
          text-align: center;
          line-height: 30px;
          margin: 0 0 20px 60px;
        }
        .tt1 {
          line-height: 46px;
          border: 1px solid #eee;
          font-size: 14px;
          font-weight: 700;
          span:nth-child(1) {
            margin-left: 80px;
          }
          span:nth-child(2) {
            margin-left: 550px;
          }
          span:nth-child(3) {
            margin-left: 120px;
          }
          span:nth-child(4) {
            margin-left: 108px;
          }
          span:nth-child(5) {
            margin-left: 100px;
          }
        }
        .goods {
          border: 1px solid #eee;
          font-size: 14px;
          height: 174px;
          display: flex;
          align-items: center;
          margin-top: 20px;
          .checkout_img {
            display: block;
            width: 20px;
            height: 20px;
            padding: 10px 20px;
          }
          .goods_img {
            width: 140px;
            height: 140px;
            border: 1px solid #eee;
            margin-left: 20px;
          }
          .goods_tt {
            width: 380px;
            height: 150px;
            font-weight: 700;
            line-height: 30px;
            margin-left: 20px;
          }
          .goods_money {
            height: 140px;
            width: 80px;
            margin-left: 120px;
          }
          .goods_num {
            height: 150px;
            margin-left: 54px;
          }
          .goods_money1 {
            height: 140px;
            width: 80px;
            margin-left: 45px;
          }
          .goods_del {
            height: 140px;
            margin-left: 50px;
          }
        }
        .box1 {
          display: flex;
          justify-content: flex-end;
          // flex-direction: column;
          font-weight: 700;
          font-size: 14px;
          margin: 20px 10px 0;
          span {
            color: #e85050;
            margin: 0 5px;
          }
        }
        .box2 {
          margin: 10px 10px 20px;
        }

        .box3 {
          line-height: 75px;
          background-color: #eee;
          display: flex;
          justify-content: flex-end;
          div {
            margin-right: 10px;
            font-size: 14px;
            font-weight: 700;
            color: #e85050;
          }
        }
        .box4 {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
          div {
            width: 124px;
            height: 46px;
            background-color: #eee;
            text-align: center;
            line-height: 46px;
            font-size: 14px;
            cursor: pointer;
          }
          div:nth-child(2) {
            background-color: #e85050;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
