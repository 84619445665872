<template>
    <div class="text_box">
        <!-- <div class="img_box">
            <img src="../assets/img/lunbo.png" alt="">
        </div> -->
        <div class="content text_box1">
            <ul class="ul_box">
                <li class="li_box" v-for="(item,i) in newsList" :key="i">
                    <div :class="is_id == item.id ? 'active' : ''" @click="index(item.id)">{{item.catename}}</div>
                </li>
            </ul>
            <div class="index_box">
                <div v-for="(item,i) in indexList" :key="i">
                  <div class="index_box_tt">{{item.title}}</div>
                  <div class="index_box_conten">{{item.desc}}</div>
                  <div class="cha" @click="chakan(item.id)">查看详情</div>
                </div>
            </div>
            <!-- <div class="detail_box">
              <div>{{detailList.title}}</div>
              <div v-html="detailList.content"></div>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      newsList: [],
      indexList: [],
      detailList: [],
      is_id: ''
    }
  },
  mounted() {
    window.document.title = window.sessionStorage.getItem('site_name') || '政采商城'
    this.news()
  },
  methods: {
    // 文章列表
    async news() {
      const { data: res } = await this.$http.get('index.php/index/post/news')
      if (res.code !== 1) return
      this.newsList = res.data
    },
    // 文章列表
    async index(id) {
      this.is_id = id
      const { data: res } = await this.$http.get('index.php/index/post/index', {
        params: {
          cat_id: id
        }
      })
      if (res.code !== 1) return
      this.indexList = res.data.data
    },
    
    chakan(id) {
      this.$router.push('/text?id=' + id + '&a=' + 1)
    }
  }
}
</script>
<style lang="less" scoped>
.text_box {
  width: 100%;
  background-color: #f0f0f0;
  padding-bottom: 60px;
  margin-top: 20px;
  .img_box {
    width: 100%;
    height: 356px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text_box1 {
    display: flex;
    background-color: #fff;
    padding: 30px 20px;
    margin-top: 20px;
    .ul_box {
      .li_box {
        div {
          width: 150px;
          height: 40px;
          font-size: 14px;
          text-align: center;
          line-height: 40px;
          background-color: #eee;
          margin-bottom: 2px;
        }
        div:hover {
          background-color: #e85050;
          color: #fff;
          cursor: pointer;
        }
        .active {
           background-color: #e85050;
           color: #fff;
        }
      }
    }
    .index_box {
      margin-left: 30px;
      font-size: 14px;
      .index_box_tt {
        font-weight: 700;
        font-size: 18px;
      }
      .index_box_conten {
        margin: 20px 0 5px;
        line-height: 24px;
        // color: #333;
        word-break: break-all;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
      }
      .cha {
        color: #e85050;
        margin-bottom: 20px;
        cursor: pointer;
      }
      
    }
   
  }
}
</style>

